<template>
  <div class="setting-item s-name">
    <esmp-input
      v-show="!isShowedEditor"
      v-model="localSetting.value"
      :label="localSetting.name"
      icon="edit"
      class="s-name__input"
      @button-click="isShowedEditor = true"
    />
    <template v-if="isShowedEditor">
      <wysiwyg
        class="s-name__editor"
        v-model="localSetting.value"
      />
      <esmp-button
        size="small"
        @click="isShowedEditor = false"
        class="mt-10"
      >
        Свернуть
      </esmp-button>
    </template>
  </div>
</template>

<script>
import Wysiwyg from '@/components/wysiwyg';

export default {
  name: 'SName',
  components: { Wysiwyg },
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowedEditor: false,
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
